
import AdminContentEditable from '~/mixins/AdminContentEditable'
import RemoveContent from '../HeocademySiteBuilder/RemoveContent.vue'
import ContentCreator from '../HeocademySiteBuilder/ContentCreator.vue'
export default {
  components: {
    ContentCreator,
    RemoveContent,
  },
  mixins: [AdminContentEditable],
  data() {
    const vm = this
    return {
      showCreator: false,
      removeContentItem: { show: false, id: -1 },
      activeIndex: 0,
      st: this.$store.state.settings.contents[this.$i18n.locale],
      swiperOption: {
        autoplay: 4500,
        spaceBetween: 20,
        slidesPerView: 3,
        loop: false,
        breakpoints: {
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
        },
        onSlideChangeEnd: (swiper) => {
          // can do something
          if(vm) vm.activeIndex = swiper.activeIndex
        },
      },
      moreAboutMe: this.$store.state.settings.contents.moreAboutMe,
    }
  },
  methods: {
    UPDATE_CONTENT(items) {
      this.moreAboutMe = items
    },
    REMOVE_CONTENT(id) {
      const index = this.moreAboutMe.findIndex((s) => s.id === id)
      if (index > -1) this.moreAboutMe.splice(index, 1)
    },
  },
}
